<template>
  <v-container
    class="products-list-page"
    fluid>
    <NewMemberReportHeader
      :loading="loading"
      @change-date="changeDate($event)"
      @find-clicked="find($event)" />
    <v-row v-if="data">
      <v-col
        md="3"
        cols="6">
        <v-card
          outlined
          class="stats-card">
          <v-card-text class="text-center">
            <div>All</div>
            <p class="title text--primary">
              {{ data.all }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        md="3"
        cols="6">
        <v-card
          outlined
          class="stats-card">
          <v-card-text class="text-center">
            <div>UID</div>
            <p class="title text--primary">
              {{ data.uid }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        md="3"
        cols="6">
        <v-card
          outlined
          class="stats-card">
          <v-card-text class="text-center">
            <div>New</div>
            <p class="title text--primary">
              {{ data.new }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        md="3"
        cols="6">
        <v-card
          outlined
          class="stats-card">
          <v-card-text class="text-center">
            <div>New Uid</div>
            <p class="title text--primary">
              {{ data.newUid }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import MemberProvider from '@/resources/MemberProvider'
import NewMemberReportHeader from '../components/NewMemberReportHeader.vue'

const MemberService = new MemberProvider()
export default {
  components: {
    NewMemberReportHeader
  },
  data () {
    return {
      data: null,
      dates: [null, null],
      loading: false
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    changeDate (date) {
      this.dates = date
    },
    find (payload) {
      this.fetchData(payload)
    },
    async fetchData (payload) {
      try {
        this.data = null
        this.loading = true

        const { data } = await MemberService.getNewMemberReport(payload)
        this.data = data
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style>
.stats-card {
  min-width: 15%;
  margin: 8px;
}
</style>
